import React from "react";
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import favicon from '../../images/favicon.ico';
import Wave from 'react-wavify';

function Kundsupport(){
    return(
        <Layout>
            <Helmet>
                <title>Kundsupport | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Kundsupport" />
                <meta name="keywords" content="Kundsupport" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Kundsupport" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.crediflow.se/sv/kundsupport" />
                <link rel="cannonical" href="https://www.crediflow.se/sv/kundsupport" />
            </Helmet>



            <section className="pt-4" style={{background: "#efefef"}}>
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="bg-kundsupport-banner bg-cover bg-no-repeat bg-center rounded-lg overflow-hidden">
                        <div className="w-full h-full" style={{background: "#1d5fb659"}}>
                            <h1 className="pt-40 pb-32  text-4xl lg:text-5xl font-bold text-white text-center">Kundsupport</h1>
                            <Wave 
                                className="-mb-2"
                                fill='#efefef'
                                paused={false}
                                options={{
                                height: 20,
                                amplitude: 50,
                                speed: 0.15,
                                points: 3
                                }}
                            />  
                        </div>
                    </div>
                </div>
            </section>


            <section className="pb-24" style={{background: "#efefef"}}>
                <div className="container mx-auto px-4 xl:px-24">
                    <iframe
                        style={{height: "800px"}}
                        className="w-full"
                        title="superoffice"
                        src="https://online.superoffice.com/Cust17637/CS/scripts/customer.fcgi?action=formFrame&formId=1"
                    />
                </div>
            </section>
        </Layout>
    );
}
export default Kundsupport;